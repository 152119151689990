<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import axios from "axios";
import {paginationHelper} from "@/helpers/pagination-helper";
import {errorCatcher} from "@/helpers/error-catcher";
import {dateUtil} from "@/helpers/date-util";
import {swalHelper} from "@/helpers/swal-helper";
import {required} from "vuelidate/lib/validators";

import CustomCardSubtitle from "@/components/custom-card/custom-card-subtitle.vue";

/**
 * Warehouse Status Component
 */
export default {
  computed: {
    paginationHelper() {
      return paginationHelper
    }
  },

  components: {
    CustomCardSubtitle,

    Layout,
    PageHeader
  },

  data() {
    return {
      submitted: false,

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        items: []
      },

      inputPage: "",

      warehouseStatus: {
        type: "CUSTOM"
      },

      showModal: false
    };
  },

  validations: {
    warehouseStatus: {
      name: {required},
      frontendHexColor: {required}
    }
  },

  methods: {

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('warehouse-status.title'),
          active: true
        }
      ]
    },

    getFields() {
      return [
        {key: "name", label: "Nazwa"},
        {key: "frontendHexColor", slot: true, label: "Kolor statusu"},
        {key: "createdAt", label: this.$t('table.created-at'), formatter: dateUtil.asDateTime},
        {key: "action", slot: true, label: this.$t('table.actions')}
      ]
    },

    async loadWarehouseStatuses() {
      let page = this.table.currentPage - 1;
      if (page > 0) {
        page = this.table.currentPage * this.table.perPage - this.table.perPage;
      }

      const result = await axios.get(`/warehouse/status/list/pagination`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "page": page,
          "size": this.table.perPage
        }
      });

      const data = result.data;

      this.table.items = data.warehouseStatuses
      this.table.totalRows = data.count;
      this.table.rows = data.count;

      if (this.mounted) {
        this.$refs.table.refresh()
      }

      return this.table.items;
    },

    createOrEditWarehouseStatus() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const json = JSON.stringify(this.warehouseStatus);

      axios.put(`/warehouse/status`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        this.$bvToast.toast(this.$t(this.warehouseStatus.id ? "warehouse-status.edit.success" : "warehouse-status.create.success"), {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        this.$refs.table.refresh();
        this.hideModal()
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    changeDeletedStatus(warehouseStatus) {
      this.submitted = true;

      const json = JSON.stringify({
        "id": warehouseStatus.id,
        "deleted": true
      });

      swalHelper.yesOrNoWithSubTitle(() => {
        axios.post(`/warehouse/status/change-deleted-status`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        }).then(() => {
          this.$bvToast.toast(this.$t('warehouse-status.deleted.success'), {
            title: this.$t('message.success'),
            variant: 'success',
            solid: true
          });

          this.$refs.table.refresh()
        }).catch((error) => {
          errorCatcher.catchErrors(error)
          this.submitted = false;
        })
      }, "")
    },

    hideModal() {
      this.showModal = false;
      this.submitted = false;
      this.warehouseStatus = {
        type: "CUSTOM",
      };
    },

    editWarehouseStatus(warehouseStatus) {
      this.warehouseStatus = Object.assign({}, warehouseStatus);
      this.showModal = true
    }

  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('warehouse-status.title')" :items="getItems()"/>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <custom-card-subtitle title="Akcje"/>

              <div class="col-xl-12">
                <a class="btn btn-success mb-2 px-4" @click="showModal = true">
                  <i class="mdi mdi-plus"></i> {{ $t('warehouse-status.create.button') }}
                </a>
              </div>
            </div>

            <ecat-table
                :table="table"
                :fields="getFields()"
                :items="loadWarehouseStatuses"
                :pagination-top="true"

            >
              <template v-slot:deleted="{ item }">
                <span :class="item.deleted ? 'badge badge-soft-success' : 'badge badge-soft-danger'"
                      class="font-size-11">{{ $t(item.deleted ? 'message.yes' : 'message.no') }}</span>
              </template>

              <template v-slot:frontendHexColor="{ item }">
                <div class="rounded-circle information-bar-circle"
                     :style="`background-color: ${item.frontendHexColor}`"/>
                <span>({{ item.frontendHexColor }})</span>
              </template>

              <template v-slot:action="{ item }">
                <template v-if="item.type === 'CUSTOM'">
                  <a @click="changeDeletedStatus(item)" class="clickable-element mr-3 text-danger"><i
                      class="mdi mdi-trash-can font-size-18"></i></a>
                </template>

                <a @click="editWarehouseStatus(item)" class="clickable-element mr-3 text-primary"><i
                    class="mdi mdi-pencil font-size-18"></i></a>
              </template>
            </ecat-table>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <b-modal
        id="modal-1"
        :scrollable="true"
        v-model="showModal"
        :title="$t(warehouseStatus.id ? 'warehouse-status.edit.title' : 'warehouse-status.create.title')"
        title-class="font-18"
        hide-footer
        @hide="hideModal"
        @esc="hideModal">
      <form>
        <div class="form-group" v-if="warehouseStatus.type === 'CUSTOM'">
          <label for="type">Nazwa</label>
          <input id="type" v-model="warehouseStatus.name"
                 :class="{ 'is-invalid': submitted && $v.warehouseStatus.name.$error }" class="form-control"
                 type="text"/>
          <div v-if="submitted && !$v.warehouseStatus.name.required" class="invalid-feedback">{{
              $t('message.required')
            }}
          </div>
        </div>

        <div class="form-group">
          <label>Kolor statusu</label>
          <b-form-input v-model="warehouseStatus.frontendHexColor" type="color"
                        :class="{ 'is-invalid': submitted && $v.warehouseStatus.frontendHexColor.$error }"/>
          <div v-if="submitted && !$v.warehouseStatus.frontendHexColor.required" class="invalid-feedback">
            {{ $t('message.required') }}
          </div>
        </div>


        <div class="text-right">
          <b-button @click="createOrEditWarehouseStatus" variant="success">
            {{ $t(warehouseStatus.id ? 'warehouse-status.edit.button' : 'warehouse-status.create.button') }}
          </b-button>
          <b-button class="ml-1" variant="danger" @click="hideModal">{{ $t('message.cancel') }}</b-button>
        </div>
      </form>
    </b-modal>

  </Layout>
</template>

<style scoped>
.information-bar-circle {
  width: 20px;
  height: 20px;
}
</style>