<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import axios from "axios";
import {paginationHelper} from "@/helpers/pagination-helper";
import {errorCatcher} from "@/helpers/error-catcher";
import {dateUtil} from "@/helpers/date-util";
import {swalHelper} from "@/helpers/swal-helper";
import {codeGenerator} from "@/helpers/code-generator";
import Swal from "sweetalert2";
import {required} from "vuelidate/lib/validators";
import warehouseStatus from "@/views/pages/admin/wholesaler/warehouse-status.vue";
import {warehouseStatusHelper} from "@/helpers/warehouse-status-helper";
import CustomCardSubtitle from "@/components/custom-card/custom-card-subtitle.vue";
import confirmModal from "../../../../components/confirm-modal.vue";
import Switches from "vue-switches";

export default {
  components: {
    confirmModal,
    CustomCardSubtitle,
    Switches,
    Layout,
    PageHeader
  },

  computed: {
    warehouseStatusHelper() {
      return warehouseStatusHelper
    },

    warehouseStatus() {
      return warehouseStatus
    },

    paginationHelper() {
      return paginationHelper
    }
  },

  data() {
    return {
      submitted: false,

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        inputPage: "",
        isBusy: false,
        dbSortBy: "",

        items: []
      },

      filtering: {
        marketId: "",
        statusId: "",
        showPermanentDeleted: false
      },

      warehouse: null,
      warehousesStatuses: null,
      // warehouseStatus: {
      //   type: ""
      // },

      modals: {

        editStatus: {
          visibleModal: false,
          code: "",
          inputCode: ""
        },

        deleteStatus: {
          visibleModal: false,
          reason: ""
        },

        changeReferral: {
          visibleModal: false,
          form: {
            warehouseId: "",
            referralCode: ""
          }
        }
      },
    };
  },

  validations: {
    modals: {
      deleteStatus: {
        reason: {required}
      },

      changeReferral: {
        form: {
          referralCode: {required}
        }
      }
    }
  },

  methods: {

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('warehouses.title'),
          active: true
        }
      ]
    },

    getFields() {
      return [
        {key: "name", label: this.$t('message.name')},
        {key: "marketI18nTranslation", label: "Rynek", formatter: value => this.$t(value)},
        {key: "status", slot: true, label: 'Status'},
        {key: "profitMargin", dbKey: "w.profit_margin", sortable: true, label: "Narzut", formatter: value => value + "%"},
        {key: "createdAt", label: this.$t('table.created-at'), formatter: dateUtil.asDateTime},
        {key: "action", slot: true, label: this.$t('table.actions')}
      ]
    },

    async loadWarehouses() {
      let page = this.table.currentPage - 1;
      if (page > 0) {
        page = this.table.currentPage * this.table.perPage - this.table.perPage;
      }

      let orderBy = {}
      if (this.table.dbSortBy) {
        orderBy = JSON.stringify({
          [this.table.dbSortBy]: this.table.sortDesc ? "DESC" : "ASC"
        })
      }

      const {data} = await axios.get(`/warehouse/pagination`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          page: page,
          size: this.table.perPage,
          filter: this.table.filter,
          ...this.filtering,
          orderBy: orderBy
        }
      });

      this.table.items = data.warehouses
      this.table.totalRows = data.count;
      this.table.rows = data.count;

      if (this.mounted) {
        this.$refs.table.refresh()
      }

      return this.table.items;
    },

    async loadWarehouseStatuses() {
      try {
        const {data} = await axios.get(`/warehouse/status`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.warehousesStatuses = new Map(data.map((obj) => [obj.id, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getWarehouseStatus(statusId) {
      if (!statusId) {
        return null
      }

      if (!this.warehousesStatuses) {
        return null
      }

      return this.warehousesStatuses.get(statusId) || null
    },

    createOrEditWarehouse(callback) {
      if (this.modals.editStatus.code !== this.modals.editStatus.inputCode) {
        Swal.fire("Błąd!", "Kod przepisany nie zgadza się!", "error");
        return
      }

      this.submitted = true;

      // stop here if form is invalid
      // this.$v.$touch();
      // if (this.$v.$invalid) {
      //   return;
      // }

      const json = JSON.stringify(this.warehouse);

      axios.post(`/warehouse`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        this.$bvToast.toast(this.$t(this.warehouse.id ? "warehouses.edit.success" : "warehouses.create.success"), {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        // const data = result.data;
        // if (this.warehouse.id) {
        //   for (const index in this.table.elements) {
        //     if (this.table.elements[index].id === this.warehouse.id) {
        //       this.table.elements[index] = data
        //       break;
        //     }
        //   }
        // } else {
        //   this.table.elements.push(data)
        // }

        this.$refs.table.refresh();
        callback()
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    deleteWarehouse() {
      this.submitted = true;

      this.$v.modals.deleteStatus.$touch();
      if (this.$v.modals.deleteStatus.$invalid) {
        return;
      }

      swalHelper.yesOrNoWithSubTitle(() => {
        axios.delete(`/warehouse`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          params: {
            "id": this.warehouse.id,
            "reason": this.modals.deleteStatus.reason
          }
        }).then(() => {
          this.hideDeleteModal()
          Swal.fire("Sukces!", "Hurtownia została usunięta", "success")
              .then(() => this.$refs.table.refresh());
        }).catch((error) => {
          errorCatcher.catchErrors(error)
          this.submitted = false;
        })
      }, "")
    },

    openEditStatusModal(warehouse) {
      this.warehouse = Object.assign({}, warehouse);
      this.modals.editStatus.visibleModal = true
      this.modals.editStatus.code = codeGenerator.generateRandomCode(6)
    },

    openDeleteModal(warehouse) {
      this.warehouse = Object.assign({}, warehouse);
      this.modals.deleteStatus.visibleModal = true
    },

    openChangeWarehouseReferralModal(warehouse) {
      this.modals.changeReferral.visibleModal = true
      this.modals.changeReferral.form.warehouseId = warehouse.id
      this.modals.changeReferral.form.name = warehouse.name
    },

    hideChangeWarehouseReferralModal() {
      this.modals.changeReferral.visibleModal = false
      this.modals.changeReferral.form.warehouseId = ""
      this.modals.changeReferral.form.referralCode = ""
    },

    changeWarehouseReferral() {
      this.submitted = true

      this.$v.modals.changeReferral.form.$touch();
      if (this.$v.modals.changeReferral.form.$invalid) {
        return;
      }

      const json = JSON.stringify({
        "warehouseId": this.modals.changeReferral.form.warehouseId,
        "referralCode": this.modals.changeReferral.form.referralCode,
      });

      axios.post(`/warehouse/update-referral-code`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then(() => {
        this.submitted = false
        this.hideChangeWarehouseReferralModal()
        Swal.fire("Sukces!", "Zmieniono kod polecającego!", "success");
      }).catch((error) => {
        this.submitted = false
        errorCatcher.catchErrors(error);
      })
    },

    hideDeleteModal() {
      this.warehouse = null
      this.modals.deleteStatus.visibleModal = false
      this.modals.deleteStatus.reason = ""
    },

    hideEditStatusModal() {
      this.warehouse = null
      this.modals.editStatus.visibleModal = false
      this.modals.editStatus.code = ""
      this.modals.editStatus.inputCode = ""
    },

    changeFilteringMarket(marketId) {
      this.filtering.marketId = marketId
      this.paginationHelper.setToFirstPageAndRefresh(this, this.table, 'table')
    },

    changeFilteringStatus(statusId) {
      this.filtering.statusId = statusId
      this.paginationHelper.setToFirstPageAndRefresh(this, this.table, 'table')
    },

    setToFirstPageAndRefresh() {
      this.paginationHelper.setToFirstPageAndRefresh(this, this.table, 'table')
    },

    async deleteFromSystem(warehouse) {
      try {
        await axios.delete(`/warehouse/permanent/${warehouse.id}`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        })

        Swal.fire("Sukces!", "Hurtownia została usunięta z systemu", "success").then(() => {
          this.$refs.confirmModal.hideModal();
          this.$refs.table.refresh()
        });
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    }

  },

  async created() {
    await this.loadWarehouseStatuses()
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('warehouses.title')" :items="getItems()"/>
    <confirm-modal ref="confirmModal" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">

            <div class="row">
              <custom-card-subtitle title="Filtrowanie"/>

              <div class="col-12 col-lg-2">
                <ecat-multiselect
                    multi-select-id="12"
                    label="Rynek"
                    save-id="id"
                    image-id="imageUrl"
                    @change="value => changeFilteringMarket(value)"
                    :custom-label="value => $t(value.i18nTranslation)"
                    placeholder="Wszystkie rynki"
                    fetch-one-url="/market"
                    load-url="/market/pagination"
                    list-name="markets"
                    :can-unselect="true"
                />
              </div>

              <div class="col-12 col-lg-2">
                <ecat-multiselect
                    multi-select-id="13"
                    @change="value => changeFilteringStatus(value)"
                    save-id="id"
                    view-id="name"
                    label="Status"
                    placeholder="Wszystkie statusy"
                    reset-option-name="Wszystkie statusy"
                    fetch-one-url="/warehouse/status"
                    load-url="/warehouse/status/list/pagination"
                    query-url="/warehouse/status/by-name"
                    list-name="warehouseStatuses"
                    param="name"
                    :can-unselect="true"
                />
              </div>

                <div class="col-lg-2">
                  <div class="d-flex flex-sm-column">
                    <label class="font-size-13">
                      Pokaż usunięte z systemu hurtownie:
                      {{ filtering.showPermanentDeleted ? "Tak" : "Nie" }}
                    </label>

                    <switches :emit-on-mount="false" @input="setToFirstPageAndRefresh" v-model="filtering.showPermanentDeleted"
                              class="my-0" color="primary"/>
                  </div>
                </div>
            </div>

            <ecat-table
                ref="table"
                :table="table"
                :fields="getFields()"
                :items="loadWarehouses"
                :pagination-top="true"

            >
              <template v-slot:status="{ item }">
                <span v-if="getWarehouseStatus(item.statusId)" class="badge badge-soft-info"
                      :style="warehouseStatusHelper.getStyle(getWarehouseStatus(item.statusId))">{{
                    getWarehouseStatus(item.statusId).name
                  }}</span>
                <span class="badge badge-soft-danger" v-else>-</span>
              </template>

              <template v-slot:action="{ item }">
                <a :href="`/dashboard/admin/warehouse/show/${item.id}`" class="clickable-element mr-3 text-primary"><i
                    class="mdi mdi-information font-size-18"></i></a>
                <a @click="openEditStatusModal(item)" class="clickable-element mr-3 text-primary"><i
                    class="mdi mdi-pencil font-size-18"></i></a>

                <a @click="openChangeWarehouseReferralModal(item)" :id="`change-referral-${item.id}`"
                   class="clickable-element mr-3 text-warning"><i class="mdi mdi-trophy-award font-size-18"></i></a>
                <b-tooltip :target="`change-referral-${item.id}`" placement="left">Zmiana kodu polecającego</b-tooltip>

                <a @click="openDeleteModal(item)" class="clickable-element mr-3 text-danger"><i class="mdi mdi-trash-can font-size-18"></i></a>

                <b-button @click="$refs.confirmModal.openModal('Usuwanie hurtowni z systemu', '', 'Usuń z systemu', () => deleteFromSystem(item))" v-if="getWarehouseStatus(item.statusId) && getWarehouseStatus(item.statusId).type === 'DELETED'" variant="danger" class="btn-sm">Usuń z systemu</b-button>
                <!--                      <a @click="editWarehouseStatus(item)" class="clickable-element mr-3 text-primary"><i class="mdi mdi-pencil font-size-18"></i></a>-->
              </template>
            </ecat-table>
          </div>
        </div>
      </div>
    </div>

    <b-modal
        v-if="warehouse"
        id="modal-1"
        v-model="modals.editStatus.visibleModal"
        title="Edytowanie Hurtowni"
        title-class="font-18"
        hide-footer
        @hide="hideEditStatusModal"
        @esc="hideEditStatusModal">
      <form>
        <div class="form-group">
          <ecat-multiselect @change="value => warehouse.statusId = value"
                            label="Wybierz nowy status hurtowni"
                            load-url="/warehouse/status/list/pagination"
                            query-url="/warehouse/status/by-name"
                            save-id="id"
                            view-id="name"
                            param="name"
                            list-name="warehouseStatuses">
          </ecat-multiselect>
        </div>

        <div class="form-group text-center">
          <p>W celu potwierdzenia zmian przepisz poniższy kod:</p>
          <p><b>{{ modals.editStatus.code }}</b></p>
          <input v-model="modals.editStatus.inputCode" type="text" class="form-control"/>
        </div>

        <div class="text-right">
          <b-button @click="createOrEditWarehouse(hideEditStatusModal)" variant="success">Zapisz</b-button>
          <b-button class="ml-1" variant="danger" @click="hideEditStatusModal">{{ $t('message.cancel') }}</b-button>
        </div>
      </form>
    </b-modal>

    <b-modal
        v-if="warehouse"
        id="modal-2"
        v-model="modals.deleteStatus.visibleModal"
        title="Usuwanie hurtowni"
        title-class="font-18"
        hide-footer
        @hide="hideDeleteModal"
        @esc="hideDeleteModal">
      <form>
        <div class="form-group">
          <label>Powód usunięcia hurtowni</label>
          <input v-model="modals.deleteStatus.reason" type="text" class="form-control"
                 :class="{ 'is-invalid': submitted && $v.modals.deleteStatus.reason.$error }"/>
          <div v-if="submitted && !$v.modals.deleteStatus.reason.required" class="invalid-feedback">
            {{ $t('message.required') }}
          </div>
        </div>

        <div class="text-right">
          <b-button @click="deleteWarehouse" variant="danger">Usuń</b-button>
          <b-button class="ml-1" variant="secondary" @click="hideDeleteModal">{{ $t('message.cancel') }}</b-button>
        </div>
      </form>
    </b-modal>

    <b-modal
        id="modal-3"
        v-model="modals.changeReferral.visibleModal"
        :title="`Zmiana kodu polecającego hurtowni: ${modals.changeReferral.form.warehouseId ? modals.changeReferral.form.name : ''}`"
        title-class="font-18"
        hide-footer
        @hide="hideChangeWarehouseReferralModal"
        @esc="hideChangeWarehouseReferralModal">
      <form>
        <div class="form-group">
          <ecat-multiselect
              @change-object="value => modals.changeReferral.form.referralCode = value.wholesalerReferralCode"
              save-id="id"
              view-id="email"
              label="Nowy kod polecającego:"
              placeholder="Wybierz użytkownika"
              fetch-one-url="/user"
              load-url="/user/list/pagination"
              query-url="/user/by-email"
              param="email"
              :show-labels="false"
              :class="{ 'is-invalid': submitted && $v.modals.changeReferral.form.referralCode.$error }"
          />
        </div>

        <div class="text-right">
          <b-button @click="changeWarehouseReferral" variant="success" class="ml-1">{{ $t('message.save') }}</b-button>
          <b-button class="ml-1" variant="danger" @click="hideChangeWarehouseReferralModal">{{
              $t('message.cancel')
            }}
          </b-button>
        </div>
      </form>
    </b-modal>

  </Layout>
</template>